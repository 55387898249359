<template>
  <div class="d-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap flex-md-wrap">
    <div v-for="header in headerHasFilter" :key="header.value" class="mt-2 mt-sm-3 mt-lg-0">
      <ads-toggle-filter
        v-if="header.settingItems && header.value === 'status'"
        :is-loading="isLoading"
        :items="header.settingItems"
        :item-key="header.value"
        :item-value="filters[header.value]"
        class="mr-0 mr-sm-2"
        @change-filter="changeFilters($event.value, $event.key)"
      />
      <c-select
        v-else-if="header.settingItems && header.value !== 'adFormatType' "
        :value="filters[header.value]"
        :items="header.settingItems"
        :placeholder="header.filterPlaceholder"
        :item-text="itemText(header.settingItems)"
        item-value="value"
        hide-details
        border-color="secondary"
        :class="['ads-filter mr-0 mr-sm-2', { 'global-full-width': isMobile }]"
        :height="isMobile ? 46 : 30"
        background-color="white"
        @change="changeFilters($event, header.value)"
      />
      <c-select
        v-else-if="header.settingItems && header.value === 'adFormatType' && isMobile "
        :value="filters[header.value]"
        :items="header.settingItems"
        :placeholder="header.filterPlaceholder"
        :item-text="itemText(header.settingItems)"
        item-value="value"
        hide-details
        border-color="secondary"
        :class="['ads-filter mr-0 mr-sm-2', { 'global-full-width': isMobile }]"
        :height="isMobile ? 46 : 30"
        background-color="white"
        @change="changeFilters($event, header.value)"
      />
    </div>
    <sorting-field
      v-if="isMobile"
      class="mt-5"
      :is-loading="isLoading"
      :options="options"
      @change-sort-by="$emit('change-sort-by', $event)"
      @change-sort-desc="$emit('change-sort-desc', $event)"
    />
  </div>
</template>

<script>
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import AdsToggleFilter from '@/views/Ads/AdsView/AdsToggleFilter.vue'
  import SortingField from '@/components/SortingField.vue'

  export default {
    name: 'AdFilters',
    components: {
      SortingField,
      AdsToggleFilter,
      CSelect
    },
    props: {
      headers: {
        type: Array,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      },
      options: {
        type: Object,
        default: () => ({})
      },
      filters: {
        type: Object,
        default: () => ({})
      },
      viewTypeIsCards: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      headerHasFilter() {
        const headers = this.headers.filter((header) => header.filters)
        const statusHeader = headers.find((filter) => filter.value === 'status')
        if (statusHeader) {
          return [statusHeader, ...headers.filter((filter) => filter.value !== 'status')]
        }
        return this.headers
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    methods: {
      changeFilters(headerValue, event = '') {
        const filters = JSON.parse(JSON.stringify(this.filters))
        filters[event] = headerValue
        this.$emit('change-filters', filters)
      },
      itemText(items) {
        return items[0].label ? 'label' : 'text'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .width-200{
    width: 200px;
  }
  ::v-deep .ads-filter .v-select__selection--comma {
    overflow: unset;
    text-overflow: unset;
  }

  .ads-filter{
    @media screen and (min-width: 1440px) {
      min-width: 154px;
    }
  }

  ::v-deep .ads-filter .v-text-field.v-text-field--solo .v-input__control input{
    display: none;
  }
</style>
