<template>
  <v-data-table
    :headers="translatedTableHeaders"
    :items="adItems"
    :loading="isLoading"
    :options="options"
    :hide-default-header="isMobile"
    :server-items-length="adsTotal"
    hide-default-footer
    class="rounded overflow-hidden table"
    v-on="$listeners"
  >
    <template v-if="isMobile" #top>
      <v-slide-y-transition mode="out-in" :hide-on-leave="true">
        <div class="secondary-lighten pb-4">
          <div class="d-flex align-center white justify-center py-4 rounded">
            <div class="mr-2">
              <v-tooltip bottom class="mr-2">
                <template #activator="{ on, attrs }">
                  <c-btn
                    v-bind="attrs"
                    icon
                    small
                    class="secondary rounded"
                    :loading="isLoading"
                    :icon-props="{
                      icon: '$update',
                      size: '16',
                      color: 'primary'
                    }"
                    v-on="on"
                    @click="$emit('fetch')"
                  />
                </template>
                <span>{{ $t('ads.refresh') }}</span>
              </v-tooltip>
            </div>
            <div class="mr-2">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <c-btn
                    v-bind="attrs"
                    icon
                    small
                    class="secondary rounded"
                    :loading="isLoading"
                    :icon-props="{
                      icon: '$reset-filters',
                      size: '16',
                      color: 'error'
                    }"
                    v-on="on"
                    @click="$emit('reset')"
                  />
                </template>
                <span>{{ $t('ads.reset') }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-slide-y-transition>
    </template>
    <template #item.name="{ item }">
      <div class="d-flex flex-column align-start">
        <span class="secondary-darken--text text-caption">#{{ formatNumber(item.id) }}</span>
        <router-link :class="{ 'pl-4': isMobile }" :to="{ name: 'Ad', params: { id: item.id } }">
          <span class="global-line-clamp">
            {{ item.name }}
          </span>
        </router-link>
      </div>
    </template>
    <template v-if="!isMobile" #header="{ props }">
      <tr>
        <th
          v-for="(header, index) in props.headers"
          :key="index"
          :style="{
            height: 0,
            'min-width': header.minWidth
          }"
        />
      </tr>
    </template>
    <template v-else #item="{ item }">
      <tr>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader :loading="isLoading" type="card" class="fill-height">
              <ad-card
                :ad="item"
                is-card-view
                class="fill-height"
                @removed-handler="$emit('removed-handler')"
              />
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </tr>
    </template>

    <template #item.status="{ item }">
      <custom-chip-status
        small
        label
        text-color="black"
        with-icon
        :value="item.status"
        :settings="statusTypes"
      />
    </template>
    <template #item.adFormatType="{ item }">
      {{ $t(`main.ad_format_types.${item.adFormatType}`) }}
    </template>

    <template #item.approvalStatus="{ item }">
      <custom-chip-status
        small
        label
        class="font-weight-medium"
        :value="item.latestApproval.status"
        :settings="adsApprovalStatusTypes"
      />
    </template>

    <template #item.actions="{ item }">
      <ads-actions :ad="item" @removed="$emit('removed-handler')" />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import AdsActions from '@/components/ads/AdsActions.vue'
  import Ads from '@/services/classes/Ads.js'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import AdCard from '@/components/ads/AdCard/Index.vue'

  export default {
    name: 'AdsTableView',
    components: {
      AdCard,
      CBtn,
      CustomChipStatus,
      AdsActions
    },
    props: {
      adsTotal: {
        type: Number,
        required: true
      },
      headers: {
        type: Array,
        required: true
      },
      viewTypeIsCards: {
        type: Boolean,
        default: false
      },
      adItems: {
        type: Array,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      },
      options: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        statusTypes: Ads.getStatusTypes()
      }
    },
    computed: {
      ...mapGetters('approvables', ['adsApprovalStatusTypes']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      translatedTableHeaders() {
        return this.headers.map((item) => ({
          ...item,
          text: `${this.$t(item.text)}${item?.additionalText ?? ''}`
        }))
      }
    },
    methods: {
      formatNumber(number) {
        return number.toLocaleString('fr-FR')
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .text-start {
    padding: 8px 15px;
  }

  ::v-deep .table tr > td + td {
    padding: 8px 10px;
  }

  ::v-deep table tbody > tr > td {
    height: 76px !important;
  }

  ::v-deep table tbody > tr > td:first-of-type {
    padding: 12px 16px !important;
    vertical-align: top;
  }

  ::v-deep thead tr th{
    height: unset !important;
    padding-top: 23px !important;
    padding-bottom: 12px !important;
  }

  ::v-deep .v-data-table__wrapper{
    padding-inline: 20px;
  }
  ::v-deep .campaign-mobile-table .v-data-table__wrapper{
    padding-inline: 0;
  }

  ::v-deep .v-data-table__wrapper  table thead tr:first-of-type > th {
    padding-left: 0 !important;
  }

  ::v-deep .v-data-table__wrapper  table tbody tr td {
    padding-left: 0 !important;
  }

</style>
