<template>
  <v-container fluid class="white">
    <v-row v-if="adItems.length || isLoading">
      <v-col v-for="ad in adItems" :key="ad.id" cols="12" md="4" xl="3">
        <v-skeleton-loader :loading="isLoading" type="card" class="fill-height">
          <ad-card
            :ad="ad"
            is-card-view
            class="fill-height"
            @removed-handler="$emit('removed-handler')"
          />
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-else class="text-center black--text opacity-60 py-2">
      {{ $t('ads.no_data_available') }}
    </div>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import AdCard from '@/components/ads/AdCard/Index.vue'

  export default {
    name: 'AdsCardsView',
    components: { AdCard },
    props: {
      adItems: {
        type: Array,
        required: true
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    created() {
      this.fetchInPageSkinGroups()
    },
    methods: {
      ...mapActions('collections', ['fetchInPageSkinGroups'])
    }
  }
</script>
