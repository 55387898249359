<template>
  <v-switch v-model="viewSwitch" :label="label" :ripple="false" hide-details color="primary" class="switcher white--text font-weight-regular" />
</template>

<script>
  export default {
    name: 'ViewSwitcher',
    props: {
      viewTypeIsCards: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      viewSwitch: {
        get() {
          return this.viewTypeIsCards
        },
        set(typeIsCards) {
          this.$emit('change', typeIsCards)
        }
      },
      label() {
        return this.viewTypeIsCards
          ? this.$t('ads.table_view')
          : this.$t('ads.card_view')
      }
    }
  }
</script>

<style lang="scss" scoped>

  ::v-deep .v-input--selection-controls__input {
    height: 18px;
  }

  ::v-deep .theme--light.v-label {
    color: var(--v-secondary-base);
    font-size: 14px;
  }

  .switcher ::v-deep .v-input__slot {
    align-items: flex-end;
  }

  .theme--light.v-input--switch ::v-deep .v-input--switch__track {
    color: var(--v-secondary-base) !important;
    opacity: 1;
    height: 18px;
    top: unset;
  }

  .theme--light.v-input--switch ::v-deep .v-input--switch__thumb {
    height: 14px;
    width: 14px;
    top: calc(50% - 7px);
    left: 3px;
    color: var(--v-secondary-darken-base);
  }

</style>
