<template>
  <div>
    <ads-cards-view
      v-if="viewTypeIsCards || isMobile"
      :ad-items="items"
      :is-loading="isLoading"
      @removed-handler="$emit('fetch')"
    />
    <ads-table-view
      v-else
      :headers="headers"
      :ad-items="items"
      :is-loading="isLoading"
      :options.sync="computedOptions"
      :ads-total="computedOptions.pageCount"
      @removed-handler="$emit('fetch')"
      @reset="$emit('reset-filters')"
      @fetch="$emit('fetch')"
    />

    <data-table-footer
      :items-per-page="computedOptions?.itemsPerPage"
      :page-count="computedOptions.pageCount"
      :current-page="computedOptions?.page"
      @change-items-per-page="changeOptions($event, 'itemsPerPage')"
      @change-current-page="changeOptions($event, 'page')"
    />
  </div>
</template>

<script>
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import AdsCardsView from '@/views/Ads/AdsView/AdsCardsView.vue'
  import AdsTableView from '@/views/Ads/AdsView/AdsTableView.vue'

  export default {
    name: 'AdsView',
    components: {
      AdsTableView,
      AdsCardsView,
      DataTableFooter
    },
    props: {
      viewTypeIsCards: {
        type: Boolean,
        required: true
      },
      adFormatTabFilter: {
        type: Object,
        required: true
      },
      headers: {
        type: Array,
        required: true
      },
      filters: {
        type: Object,
        required: true
      },
      options: {
        type: Object,
        default: () => ({})
      },
      isLoading: {
        type: Boolean,
        required: true
      },
      pageCount: {
        type: Number,
        required: true
      },
      items: {
        type: Array,
        required: true
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      computedOptions: {
        get() {
          return this.options
        },
        set(newValue) {
          this.$emit('change-options', newValue)
        }
      }
    },
    watch: {
      adFormatTabFilter() {
        if (this.adFormatTabFilter.value !== this.filters.adFormatType) {
          this.changeFilters(this.adFormatTabFilter)
        }
      }
    },
    created() {
      if (this.viewTypeIsCards) {
        this.$emit('fetch')
      }
    },
    methods: {
      changeOptions(value, key) {
        const optionsCopy = JSON.parse(JSON.stringify(this.options))
        optionsCopy[key] = value
        if (key !== 'page') {
          optionsCopy.page = 1
        }
        this.$emit('change-options', optionsCopy)
      },
      changeFilters({ key, value }) {
        const filtersCopy = JSON.parse(JSON.stringify(this.filters))
        filtersCopy[key] = value
        if (key === 'adFormatType') {
          this.$emit('change-tab', { key, value })
        }
        this.$emit('change-filters', filtersCopy)
      }
    }
  }
</script>
