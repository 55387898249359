<template>
  <div v-if="isMobile">
    <c-select
      :class="[{ 'global-full-width': isMobile }]"
      :value="itemValue"
      :items="adStatusesWithLabel"
      :loading="isLoading"
      item-text="label"
      item-value="value"
      hide-details
      border-color="secondary-lighten"
      :height="isMobile ? 46 : 30"
      background-color="white"
      @change="$emit('change-filter', { value: $event, key: itemKey })"
    />
  </div>
  <div v-else class="d-flex">
    <c-toggle-buttons
      :value="itemValue"
      :buttons="adStatusesWithLabel"
      mandatory
      item-text="label"
      class="only-icon"
      :button-height="30"
      :button-attrs="{
        color: 'transparent primary--text',
        class: 'text-caption d-flex align-center justify-center ma-0'
      }"
      @change="$emit('change-filter', { value: $event, key: itemKey })"
    />
  </div>
</template>

<script>
  import CToggleButtons from '@clickadilla/components/ui/CToggleButtons.vue'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'

  export default {
    name: 'AdsToggleFilter',
    components: { CSelect, CToggleButtons },
    props: {
      itemValue: {
        type: String,
        default: undefined
      },
      itemKey: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      adStatusesWithLabel() {
        const statusType = this.items.map((status) => ({
          ...status,
          label: this.isMobile ? this.$t(status.label) : null
        }))
        const allAdFormats = {
          value: '',
          label: this.isMobile
            ? this.$t('ads.all_status')
            : this.$t('ads.all')
        }
        return [allAdFormats, ...statusType]
      }
    }
  }
</script>

<style scoped lang="scss">
::v-deep .only-icon .v-icon{
  margin:0;
}

::v-deep .only-icon .v-btn-toggle > .v-btn.v-btn {
  padding:0;
  min-width: 30px;
}
</style>
